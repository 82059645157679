import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const actDeleteCart = createAsyncThunk(
  "api/deletecart",
  async (id, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const token = getState().login.token;
    const user_id = getState().profileSlice.cartprofile.id;

    try {
      const formData = new FormData();

      formData.append("user_id", user_id);
      formData.append("product_id", id);
      const res = await axios.post(`/cart/remove-from-cart`, formData, {
        mode: "cors", // Enable CORS mode
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          jwt: token, // Replace with your actual JWT token
        },
      });
      return res.data;
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

export default actDeleteCart;
