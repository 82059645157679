import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import linkd from "../../../assests/svg/linkd.svg";
import twit from "../../../assests/svg/twiter.svg";
import insta from "../../../assests/svg/insta.svg";
import logo from "../../../assests/logo3.png";
import SvgIcon from "../../DComponents/icons/SvgIcon";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="bg-[#090e21] pb-[100px] md:py-[40px] md:pt-[30px]">
        <div className=" w-[95%] mx-auto flex flex-col md:flex-row justify-between">
          <div className="box1 text-center md:text-right">
            <div className="relative md:bottom-4 flex justify-center md:text-right">
              <img src={logo} alt="" className=" w-[60px]" />
            </div>
            <p className="text-white text-center my-[px]">
              {t("footer.help_message_1")} <br />
              {t("footer.help_message_2")} <br />
              {t("footer.help_message_3")}
            </p>
            <div className="flex justify-between mt-6 px-4 md:py-0">
              <SvgIcon path={linkd} />
              <SvgIcon path={linkd} />

              <SvgIcon path={insta} />
              <SvgIcon path={twit} />
              <SvgIcon path={twit} />
            </div>
          </div>
          <div className="md:block hidden">
            <h2 className="text-white font-sans my-3 md:my-0 text-center md:text-right text-[30px]">
              {t("footer.quick_links")}
            </h2>
            <div className="text-[#cccccc]  text-center mx-auto md:mx-0 flex flex-row md:flex-col">
              <Link className="px-3 md:py-2">{t("footer.about_us")}</Link>
              <Link className="px-3 md:py-2">{t("footer.subscribe_now")}</Link>
              <Link className="px-3 md:py-2">{t("footer.site_map")}</Link>
              <Link className="px-3 md:py-2">{t("footer.team")}</Link>
            </div>
          </div>
          <div className="md:block hidden">
            <h2 className="text-white text-center  font-sans my-3 md:my-0 text-[30px]">
              {t("footer.support")}
            </h2>
            <div className="text-center text-[#cccccc] mx-auto md:mx-0 flex flex-row md:flex-col">
              <Link className="px-3 md:py-2">{t("footer.contact_us")}</Link>
              <Link className="px-3 md:py-2">{t("footer.faqs")}</Link>
              <Link className="px-3 md:py-2">
                {t("footer.technical_support")}
              </Link>
              <Link className="px-3 md:py-2">
                {t("footer.customer_service")}
              </Link>
            </div>
          </div>
          <div className="md:block hidden">
            <h2 className="text-white font-sans my-3 md:my-0  text-center text-[30px]">
              {t("footer.help")}
            </h2>
            <div className=" text-center text-[#cccccc] mx-auto md:mx-0 flex flex-row md:flex-col">
              <Link className="px-2 md:py-2">{t("footer.privacy_policy")}</Link>
              <Link className="px-2 md:py-2">
                {t("footer.terms_conditions")}
              </Link>
              <Link className="px-2 md:py-2">{t("footer.security")}</Link>
            </div>
          </div>
        </div>
        <div className="flex md:flex-row flex-col justify-between text-[#ffff] relative top-[30px] px-1 pr-5">
          <p className="text-center mb-4 md:mb-0 md:text-right">
            {t("footer.copyright")} © 2024 {t("footer.brand_name")}
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
