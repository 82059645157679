import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assests/logo3.png";
import SvgIcon from "../../DComponents/icons/SvgIcon";
import { useTranslation } from "react-i18next";

const Headerform = () => {
  const { t } = useTranslation();
  return (
    <div className=" flex flex-col gap-9">
      <div className="   z-50 flex  justify-around   items-center border-b-[1px]  fixed top-0 left-0 right-0 bg-[#ffff] ">
        <div className=" flex md:mr-3">
          <div className=" w-[40px]  md:w-[100px]">
            <Link to={"/"}>
              <SvgIcon path={Logo} />
            </Link>
          </div>
        </div>

        <div className="flex md:gap-0 items-center  md:space-x-[10px] md:ml-[40px] ">
          <Link
            className="text-gray-700 text-[15px] md:text-[20px] border-b-[3px] whitespace-nowrap border-[#b68d33] pb-2 focus:outline-none ml-[10px] md:ml-6"
            to={"/"}
          >
            {t("header.homeform")}{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Headerform;
