import { lazy, Suspense, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Loading from "../components/DComponents/Loading/Loading";
import AuthProtectPage from "../components/DComponents/AuthProtectPage/AuthProtectPage";
import ProtectAuthPage from "../components/DComponents/AuthProtectPage/ProtectAuthPage";

// Layouts
import Mainlayout from "../Layout/Mainlayout";
import UserLayout from "../Layout/UserLayout";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/Auth/Login/login";

// Lazy load the components
const Home = lazy(() => import("../pages/Home/Home"));
const Loginform = lazy(() => import("../pages/Auth/Login"));
const Signupform = lazy(() => import("../pages/Auth/Register"));
const Forgetform = lazy(() => import("../pages/Auth/ForgetPassword"));
const AdsList = lazy(() => import("../pages/Adds/AdsList"));
const AddDetails = lazy(() => import("../components/componentAddlist/Slider"));
const Visapage = lazy(() => import("../pages/Payment/Visapage"));
const Acount = lazy(() => import("../pages/ProfileInfo/Acount"));
const Cartpage = lazy(() => import("../pages/Cart/Cart"));
const Paymenttow = lazy(() => import("../pages/Payment/Paymenttow"));

const AppRouter = () => {
  const dispatch =  useDispatch()
  useEffect(() => {
    const loginTime = localStorage.getItem('loginTime');
    if (loginTime) {
      const now = Date.now();
      const twoHours = 2 * 1000; // ساعتين بالمللي ثانية
      const timePassed = now - parseInt(loginTime, 10);

      if (timePassed >= twoHours) {
        dispatch(logout());
        localStorage.removeItem('loginTime'); // إزالة توقيت تسجيل الدخول بعد الخروج
      } else {
        // إذا لم تمر ساعتين، قم بتعيين مؤقت لتسجيل الخروج
        const timeLeft = twoHours - timePassed;
        const timer = setTimeout(() => {
          dispatch(logout());
          localStorage.removeItem('loginTime');
        }, timeLeft);

        // تنظيف المؤقت عند إلغاء تحميل الـ component
        return () => clearTimeout(timer);
      }
    }
  }, [dispatch]);
 
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Mainlayout />,
      children: [
        {
          index: true,
          element: <Home />,
        },
        {
          path: "adlist",
          element: <AdsList />,
        },
        {
          path: "addDetails/:id",
          element: <AddDetails />,
        },
        {
          path: "acount",
          element: (
            <>
              <AuthProtectPage>
                <Acount />
              </AuthProtectPage>
            </>
          ),
        },
        {
          path: "cart",
          element: (
            <>
              <AuthProtectPage>
                <Cartpage />
              </AuthProtectPage>
            </>
          ),
        },
        {
          path: "visaform",
          element: <Visapage />,
        },
        {
          path: "payment",
          element: <Paymenttow />,
        },
      ],
    },
    {
      path: "/user", element: <UserLayout />, children: [
        {
          path: "login",
          element:
            <>
              <ProtectAuthPage>
                <Loginform />
              </ProtectAuthPage>
            </>
          ,
        },
        {
          path: "signup",
          element:

            <>
              <ProtectAuthPage>
                <Signupform />
              </ProtectAuthPage>
            </>,
        },
        {
          path: "forget",
          element:
            <>
              <ProtectAuthPage>
                <Forgetform />
              </ProtectAuthPage>
            </>,
        },
      ]
    }
  ]);

  return (
    <>
      <Suspense
        fallback={
          <>
            <Loading />
          </>
        }
      >
      
        <RouterProvider router={router} />
      </Suspense>
    </>
  );
};

export default AppRouter;
