import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import i18n from "../../utils/i18n";

export const useHeader = () => {
  const token = useSelector((state) => state.login.token);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const dropdownRef = useRef(null);
  const toggleLang = () => {
    const currentLang =
      i18n.language || window.localStorage.getItem("i18nextLng");

    if (currentLang === "ar") {
      i18n.changeLanguage("en"); // Change to English
      window.localStorage.setItem("i18nextLng", "en");
    } else {
      i18n.changeLanguage("ar"); // Change to Arabic
      window.localStorage.setItem("i18nextLng", "ar");
    }
    window.location.reload()
  };

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const [open, setOpen] = useState(false);

  const toggleDropdown = () => {
    setOpen(!open);
  };
  return { toggleDropdown, token, isOpen, toggleLang, dispatch , open , setIsOpen} ;
};
