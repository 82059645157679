import { createSlice } from "@reduxjs/toolkit";
import { actprofile } from "./actions/actprofile";

// Initial state
const initialState = {
  cartprofile: [], // To store cart data
  loading: false, // To track loading state
  error: null, // To store error state
};

// Cart slice with extraReducers for async actions
const profileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actprofile.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error on new request
      })
      .addCase(actprofile.fulfilled, (state, action) => {
        state.cartprofile = action.payload;
        state.loading = false; // Data loaded successfully
      })
      .addCase(actprofile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Store error message
        if (action.error.message === "User not found") {
          state.cartprofile = []; // Clear cart data if user not found
        }
      });
  },
});

export default profileSlice.reducer;
