import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchadds = createAsyncThunk(
  "adds/fetchadds",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`/store/get-products`);
      const data = res.data; // Axios automatically parses the JSON
      console.log(data);
      return data;
    } catch (err) {
      return rejectWithValue(err.response?.data || err.message); // Properly return error
    }
  }
);

const initialState = {
  adds: [],
  loading: "idle",
  error: null,
};

const addSlice = createSlice({
  name: "adds",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchadds.pending, (state) => {
      state.loading = "pending";
      state.error = null;
    });
    builder.addCase(fetchadds.fulfilled, (state, action) => {
      state.loading = "success";
      // console.log(action);
      state.adds = action.payload;
    });
    builder.addCase(fetchadds.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
  },
});
export default addSlice.reducer;
