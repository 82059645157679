import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assests/logo3.png";
import { useTranslation } from "react-i18next";
import Hamburger from "hamburger-react";
import { useHeader } from "../../../Hooks/Header/useHeader";

function SidebarMenue() {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const { token } = useHeader();

  return (
    <>
      <button>
        <Hamburger toggled={show} toggle={setShow} size={30} color="#b68d33" />
      </button>

      {/* Offcanvas Overlay */}
      <div
        className={`fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity duration-300 ${show ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
        onClick={() => setShow(false)}
      ></div>

      {/* Offcanvas Panel */}
      <div
        className={`fixed w-[80%] top-0 right-0  h-full bg-white shadow-lg transition-transform duration-500 transform ${show ? "translate-x-0" : "translate-x-full"
          }`}
      >
        <div className="flex justify-between items-center p-4">
          <div>
            <Link to={"/"}>
              <img
                src={logo}
                alt="logo"
                className="rounded-full w-[40px] md:w-[60px] mx-auto"
              />
            </Link>
          </div>
          <button className="text-xl" onClick={() => setShow(false)}>
            &times;
          </button>
        </div>
        <div className="p-4">
          <ul className="flex flex-col gap-4">
            <Link className="mb-7 border p-3 rounded-[10px] md:mb-0 hover:text-[#b68d33] hover:font-semibold md:hover:border-b-[3px] border-[#b68d33] md:pb-[8px]">
              {t("header.egypt")}
            </Link>
            <Link className="mb-7 border p-3 rounded-[10px] md:mb-0 hover:text-[#b68d33] hover:font-semibold md:hover:border-b-[3px] border-[#b68d33] md:pb-[8px]">
              {t("header.home")}
            </Link>
            <Link
              to={"adlist"}
              className="mb-7 border p-3 rounded-[10px] md:mb-0 hover:text-[#b68d33] hover:font-semibold md:hover:border-b-[3px] border-[#b68d33] md:pb-[8px]"
            >
              {t("header.products")}
            </Link>
            {token ? null : (
              <>
                <Link
                  to={"/user/login"}
                  className="mb-7 border p-3 rounded-[10px] md:mb-0 hover:text-[#b68d33] hover:font-semibold md:hover:border-b-[3px] border-[#b68d33] md:pb-[8px]"
                >
                  {token ? null : "تسجيل الدخول"}
                </Link>
              </>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default SidebarMenue;
