import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

export const ProtectAuthPage = ({ children }) => {
    const accessToken = useSelector((state) => state.login.token);

    if (accessToken) {
        return <Navigate to={"/"} />;
    }

    return <>{children}</>;
};

export default ProtectAuthPage;
