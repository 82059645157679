import { Outlet } from 'react-router-dom'
import Header from '../components/Common/Headers/Header'
import Footer from '../components/Common/Footers/Footer'
import { useEffect } from 'react'


const Mainlayout = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  )
}

export default Mainlayout