import React from 'react'

const SvgIcon = ({path}) => {
  return (
    <div>
        <img src={path} alt='fdsa' />
    </div>
  )
}

export default SvgIcon