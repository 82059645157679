import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import ar from "./locals/ar/ar.json";
import en from "./locals/en/en.json"; // Corrected path casing

const resources = {
  en: {
    translation: en,
  },
  ar: {
    translation: ar,
  },
};

i18n
  .use(initReactI18next) // Connects react-i18next to i18n
  .use(LanguageDetector)  // Language detection based on user's preferences
  .init({
    resources,
    fallbackLng: "ar", // Fallback language is Arabic
    interpolation: {
      escapeValue: false, // React already escapes by default
    },
    detection: {
      order: ["localStorage", "navigator", "htmlTag", "path", "subdomain"],
      caches: ["localStorage"], // Cache user language in localStorage
    },
  });

export default i18n;
