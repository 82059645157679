import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Action to get the cartData
const actCart = createAsyncThunk("api/cart", async (_, thunkAPI) => {
  const { rejectWithValue, getState } = thunkAPI;
  const token = getState().login.token;
  const user_id = getState().profileSlice.cartprofile.id;

  try {
    const { data } = await axios.get(`/cart/get-cart/${user_id}`, {
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        jwt: token,
      },
    });

    return data;
  } catch (error) {
    rejectWithValue(error);
  }
});

export default actCart;
