import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchcategories = createAsyncThunk(
  "categories/fetchcategories",
  async (_, thunkApI) => {
    try {
      const res = await axios.get(`/api/get-categories`)
      const data = await res.json();
      return data;
    } catch (err) {
      console.log(err);
    }
  }
);

const initialState = {
  categories: null,
  loading: "idle",
  error: null,
};

const categrySlice = createSlice({
  name: "categories",
  initialState,

  extraReducers: (builder) => {
    builder.addCase(fetchcategories.pending, (state) => {
      state.loading = "pending";
      state.error = null;
    });
    builder.addCase(fetchcategories.fulfilled, (state, action) => {
      state.loading = "success";
      state.categories = action.payload;
    });
    builder.addCase(fetchcategories.rejected, (state, action) => {
      state.loading = "falied";
      state.error = action.error.message;
    });
  },
});
export default categrySlice.reducer;
