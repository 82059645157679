import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../../../assests/logo3.png";
import Translate from "../../../assests/svg/translate.svg";
import SvgIcon from "../../DComponents/icons/SvgIcon";
import SidebarMenue from "./SidebarMenue";
import { logout } from "../../../store/Auth/Login/login";
import { useHeader } from "../../../Hooks/Header/useHeader";
import { FaCartShopping } from "react-icons/fa6";
import useMobileScreen from "../../../Hooks/MobileScreent/useMobileScreeen";
import { CgProfile } from "react-icons/cg";
import i18n from "../../../utils/i18n";
import { useSelector } from "react-redux";
import { resetCart } from "../../../store/CartSlice/CartSlice";
import { useEffect } from "react";

const Header = () => {
  const { t } = useTranslation();

  const { dispatch, isOpen, toggleLang, token, setIsOpen } = useHeader();

  const { mobileStatus } = useMobileScreen();

  const logoutReset = () => {
    dispatch(logout());
    dispatch(resetCart());
  };

  const cartData = useSelector((state) => state.CartSlice.cart?.data?.products);
  const totalAmount = cartData
    ? cartData.reduce((total, item) => total + item.amount, 0)
    : 0;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {mobileStatus ? (
        <>
          <div className="flex items-center justify-between p-4 absolute left-0 right-0 top-0 z-50">
            <div>
              <Link to={"/"}>
                <img
                  src={logo}
                  alt="logo"
                  className=" rounded-full w-[40px] md:w-[60px] mx-auto"
                />
              </Link>
              <ul className=" xl:flex hidden items-center gap-6 border-2 border-red-500 justify-center">
              
              <Link className="mb-7 md:mb-0 hover:text-[#b68d33] hover:font-semibold md:hover:border-b-[3px] border-[#b68d33] md:pb-[8px]">
                {t("header.home")}
              </Link>
              <Link
                to={"adlist"}
                className="mb-7 md:mb-0 hover:text-[#b68d33] hover:font-semibold md:hover:border-b-[3px] border-[#b68d33] md:pb-[8px]"
              >
                {t("header.products")}
              </Link>
            </ul>
            </div>
            <div className="flex items-center gap-2">
              {token ? (
                <>
                  <div className="cursor-pointer relative">
                    <button onClick={() => setIsOpen(!isOpen)}>
                      <CgProfile color="#b68d33" size={30} alt="Profile" />
                    </button>
                    {isOpen ? (
                      <div className="absolute w-[200px] rounded-md text-white text-[15px] font-semibold bg-[#b68d33] z-50 top-full mt-2 right-[-140px]">
                        <div className="p-3">
                          <Link to={"acount"}>{t("header.profile")}</Link>
                        </div>
                        <hr />
                        <button className="p-3" onClick={() => logoutReset()}>
                          {t("header.logout")}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="flex items-center gap-2">
                    <div
                      className=" flex items-center gap-2   md:gap-3 font-semibold cursor-pointer hover:text-[#b68d33]"
                      onClick={() => toggleLang()}
                    >
                      <span
                        className={`${window.location.pathname === "/"
                          ? "text-white"
                          : "text-black"
                          }`}
                      >
                        {i18n.language === "ar" ? "En" : "Ar"}
                      </span>
                      <SvgIcon path={Translate} />
                    </div>
                    <div className="relative">
                      <Link to={"cart"}>
                        {window.location.pathname === "/" ? (
                          <FaCartShopping color="white" size={30} />
                        ) : (
                          <FaCartShopping color="black" size={30} />
                        )}
                        <span className="absolute  top-[-10px] right-[5px] bg-[#b68d33] text-white rounded-full w-[20px] h-[20px] px-[4px] py-[2px] items-center justify-center text-xs">
                          {totalAmount}
                        </span>
                      </Link>
                    </div>
                  </div>
                  <SidebarMenue />
                </>
              ) : (
                <>
                  <div
                    className="   font-semibold cursor-pointer hover:text-[#b68d33] flex items-center gap-2"
                    onClick={() => toggleLang()}
                  >
                    <span
                      className={`${window.location.pathname === "/"
                        ? "text-white"
                        : "text-black"
                        }`}
                    >
                      {i18n.language === "ar" ? "EN" : "Ar"}
                    </span>
                    <SvgIcon path={Translate} />
                  </div>
                  <div className="relative">
                    <Link to={"cart"}>
                      {window.location.pathname === "/" ? (
                        <FaCartShopping color="white" size={30} />
                      ) : (
                        <FaCartShopping color="black" size={30} />
                      )}
                      <span className="absolute  top-[-10px] right-[5px] bg-[#b68d33] text-white rounded-full w-[20px] h-[20px] px-[4px] py-[2px] items-center justify-center text-xs">
                        {totalAmount}
                      </span>
                    </Link>
                  </div>

                  <SidebarMenue />
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className={`z-40 flex p-2  justify-between items-center ${window.location.pathname === "/"
              ? "bg-transparent text-white absolute left-0 top-0 right-0 "
              : "bg-white fixed text-black left-0 top-0 right-0 shadow-md "
              }   `}
          >
            {/* Logo and Search Bar */}
            <div className=" flex items-center">
              <Link to={"/"}>
                <img
                  src={logo}
                  alt="logo"
                  className=" rounded-full w-[40px] md:w-[60px] mx-auto"
                />
              </Link>
              <ul className=" flex items-center gap-3 mx-4 justify-center ">
              
              <Link className="mb-7 md:mb-0 hover:text-[#b68d33] hover:font-semibold md:hover:border-b-[3px] border-[#b68d33] md:pb-[8px]">
                {t("header.home")}
              </Link>
              <Link
                to={"adlist"}
                className="mb-7 md:mb-0 hover:text-[#b68d33] hover:font-semibold md:hover:border-b-[3px] border-[#b68d33] md:pb-[8px]"
              >
                {t("header.products")}
              </Link>
            </ul>
            </div>
            {/* Links */}
            {/* <ul className=" flex items-center gap-6 justify-center xl:mr-[150px]">
              
              <Link className="mb-7 md:mb-0 hover:text-[#b68d33] hover:font-semibold md:hover:border-b-[3px] border-[#b68d33] md:pb-[8px]">
                {t("header.home")}
              </Link>
              <Link
                to={"adlist"}
                className="mb-7 md:mb-0 hover:text-[#b68d33] hover:font-semibold md:hover:border-b-[3px] border-[#b68d33] md:pb-[8px]"
              >
                {t("header.products")}
              </Link>
            </ul> */}
            {/* Right Side of the Header */}
            <div className="flex items-center gap-4  ">
              {token ? (
                <div className="flex items-center">
                  {/* Language Button */}
                  <div className="md:flex hidden items-center   ">
                    <span>{t("header.language")}</span>
                    <button className="" onClick={() => toggleLang()}>
                      <SvgIcon path={Translate} />
                    </button>
                  </div>

                  {/* Cart Icon */}
                  <div className="  md:block hidden">
                    <div className="md:relative mx-2">
                      <Link to={"cart"}>
                        {window.location.pathname === "/" ? (
                          <FaCartShopping color="white" size={30} />
                        ) : (
                          <FaCartShopping color="black" size={30} />
                        )}
                        <span className="md:absolute text-center  top-[-10px] right-[5px] bg-[#b68d33]  text-white rounded-full w-[20px] h-[20px] items-center justify-center text-xs">
                          {totalAmount}
                        </span>
                      </Link>
                    </div>
                  </div>

                  {/* Profile Menu */}
                  <div className="cursor-pointer md:flex hidden items-center relative">
                    <button
                      className=" pl-2"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <CgProfile color="#b68d33" size={30} alt="Profile" />
                    </button>

                    {isOpen ? (
                      <div className="absolute w-[200px] rounded-md text-white text-[15px] font-semibold bg-[#b68d33] z-50 top-full mt-2  md:left-0  ">
                        <div className="p-3">
                          <Link to={"acount"}>{t("header.profile")}</Link>
                        </div>
                        <hr />
                        <button className="p-3" onClick={() => logoutReset()}>
                          {t("header.logout")}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                <div className="flex items-center gap-3">
                  <Link
                    className={` ${window.location.pathname === "/"
                      ? "text-white"
                      : "text-black"
                      } border-2 p-2  rounded-md `}
                    to={"/user/login"}
                  >
                    {t("header.login")}
                  </Link>
                  <div
                    className="md:flex hidden items-center md:gap-3 font-semibold cursor-pointer hover:text-[#b68d33]"
                    onClick={() => toggleLang()}
                  >
                    <span
                      className={`${window.location.pathname === "/"
                        ? "text-white"
                        : "text-black"
                        }`}
                    >
                      {i18n.language === "ar" ? "EN" : "Ar"}
                    </span>
                    <SvgIcon path={Translate} />
                  </div>
                  <div className="relative ">
                    <Link to={"cart"}>
                      {window.location.pathname === "/" ? (
                        <FaCartShopping color="white" size={30} />
                      ) : (
                        <FaCartShopping color="black" size={30} />
                      )}
                      <span className="md:absolute text-center  top-[-15px] right-[3px] bg-[#b68d33] text-white rounded-full w-[20px]   items-center justify-center px-[3px] py-[2px] text-xs">
                        {totalAmount}
                      </span>
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
