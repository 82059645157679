import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosErrorHandler from "../../../api/AxiosErrorHandler";
import axios from "axios";

const initialState = {
  token: "",
  loading: "ideal",
  error: null,
  alertStatus: false,
};

export const logindata = createAsyncThunk(
  "login/logindata",
  async (datalogin, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`/auth/Login`, datalogin);
      console.log(res);
      return res;
    } catch (err) {
      // Use axiosErrorHandler to format the error
      const error = axiosErrorHandler(err);
      // Ensure rejectWithValue is returned here
      console.log(error);
      return rejectWithValue(error);
    }
  }
);

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout: (state) => {
      state.token = null;
      state.alertStatus = true;
      window.localStorage.removeItem("btnCount");
    },
    resetAlertStatus: (state) => {
      state.alertStatus = false;
    },
    alertSet: (state) => {
      state.loading = "idle";
    },
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logindata.pending, (state) => {
        // console.log(action);
        state.loading = "pending";
        state.error = null;
      })
      .addCase(logindata.fulfilled, (state, action) => {
        state.loading = "success";
        // console.log(action.payload.data.jwt);a
        state.token = action.payload.data.jwt;
      })
      .addCase(logindata.rejected, (state, action) => {
        state.loading = "failed";
        state.error = action.payload.detail; // This will now correctly capture the error
      });
  },
});
export const { logout, alertSet, resetAlertStatus, resetError } =
  loginSlice.actions;

export default loginSlice.reducer;
