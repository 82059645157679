import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const actUpdateCart = createAsyncThunk(
  "api/updatecart",
  async ({ id, amount }, thunkAPI) => {
    const { rejectWithValue, getState } = thunkAPI;
    const token = getState().login.token;
    const user_id = getState().profileSlice.cartprofile.id;

    try {
      // const formData = new FormData();
      // formData.append("user_id", user_id);
      // formData.append("product_id", id);
      // formData.append("amount", Number(amount)); // Ensure amount is a number

      const res = await axios.post(
        `/cart/update-amount-product`,
        {
          user_id: user_id,
          product_id: id,
          amount: Number(amount),
        },
        {
          mode: "cors",
          credentials: "include",
          headers: {
            jwt: token, // JWT token
            // Content-Type will be set automatically for FormData
          },
        }
      );
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export default actUpdateCart;
