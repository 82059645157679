import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchsigeladd = createAsyncThunk(
  "singeladd/fetchsigeladd",
  async (id, thunkApI) => {
    try {
      const res = await axios.get(`/store/get-single-product/${id}`);
      console.log(res);
      return res.data.data;
    } catch (err) {
      console.log(err);
    }
  }
);

const initialState = {
  oneadd: [],
  loading: "idle",
  error: null,
};

const singeladdSlice = createSlice({
  name: "singeladd",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchsigeladd.pending, (state) => {
      state.loading = "pending";
      state.error = null;
    });
    builder.addCase(fetchsigeladd.fulfilled, (state, action) => {
      state.oneadd = action.payload;
      state.loading = "success";
    });
    builder.addCase(fetchsigeladd.rejected, (state, action) => {
      state.loading = "falied";
      state.error = action.error.message;
    });
  },
});
export default singeladdSlice.reducer;
