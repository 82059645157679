import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

export const AuthProtectPage = ({ children }) => {
  const accessToken = useSelector((state) => state.login.token);

  if (!accessToken) {
    return <Navigate to={"/user/login?message=login-required"} />;
  }

  return <>{children}</>;
};

export default AuthProtectPage;
