import { createSlice } from "@reduxjs/toolkit";
import actCart from "./actions/actCart";
import actAddCart from "./actions/actAddCart";
import actDeleteCart from "./actions/actDeleteCart";
import actUpdateCart from "./actions/actUpdateCart";

const initialState = {
  cart: [],
  loading: "idle",
  error: null,
  alertStatus: false,
  deleteStatus: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    resetCart: (state) => {
      state.cart = [];
    },
    deleteAlertStatus: (state) => {
      state.deleteStatus = false;
    },
    updateAlertStatus: (state) => {
      state.alertStatus = false;
    },
    alertSet: (state) => {
      state.alertStatus = false;
    },
  },
  extraReducers: (builder) => {
    // Bulider To get the cart Data
    builder.addCase(actCart.pending, (state) => {
      state.loading = "pending";
      state.error = null;
    });
    builder.addCase(actCart.fulfilled, (state, action) => {
      state.loading = "success";
      state.cart = action.payload;
    });
    builder.addCase(actCart.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
    // Builder For Add To Cart
    builder.addCase(actAddCart.pending, (state) => {
      state.loading = "pending";
      state.error = null;
    });
    builder.addCase(actAddCart.fulfilled, (state, action) => {
      state.loading = "success";
      state.alertStatus = true;
      state.cart = action.payload;
    });
    builder.addCase(actAddCart.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
    // Builder For Delete From Cart
    builder.addCase(actDeleteCart.pending, (state) => {
      state.loading = "pending";
      state.error = null;
    });
    builder.addCase(actDeleteCart.fulfilled, (state, action) => {
      state.loading = "success";
      state.deleteStatus = true;
      state.cart = action.payload;
    });
    builder.addCase(actDeleteCart.rejected, (state, action) => {
      state.loading = "failed";
      state.error = action.payload;
    });
    // Builder To Update the Cart
    builder.addCase(actUpdateCart.pending, (state) => {
      state.loading = "pending";
      state.error = null;
    });
    builder.addCase(actUpdateCart.fulfilled, (state, action) => {
      state.loading = "success";
      state.alertStatus = true;
      state.cart = action.payload;
    });
    builder.addCase(actUpdateCart.rejected, (state, action) => {
      state.loading = "pending";
      state.error = action.payload;
    });
  },
});

export const { resetCart, deleteAlertStatus, updateAlertStatus, alertSet } =
  cartSlice.actions;

export default cartSlice.reducer;
