import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosErrorHandler from "../../../api/AxiosErrorHandler";

const initialState = {
  data: [],
  loading: "idle", // Initial state
  error: null,
};

// Async thunk for registering data
export const registerdata = createAsyncThunk(
  "register/registerdata",
  async (dataregister, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const response = await axios.post(`/auth/Register`, dataregister);
      return response.data; // Return the data on success
    } catch (err) {
      const error = axiosErrorHandler(err); // Format error
      console.log(error);
      return rejectWithValue(error); // Return formatted error
    }
  }
);

const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    resetError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerdata.pending, (state) => {
        state.loading = "pending"; // Set loading to pending
        state.error = null; // Clear previous errors
      })
      .addCase(registerdata.fulfilled, (state, action) => {
        state.loading = "idle"; // Set loading to idle after success
        state.data = action.payload; // Set the data from the action payload
      })
      .addCase(registerdata.rejected, (state, action) => {
        state.loading = "failed"; // Set loading to failed
        state.error = action.payload; // Set error from action payload
      });
  },
});

export const { resetError } = registerSlice.actions;
export default registerSlice.reducer;
