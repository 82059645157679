import { PulseLoader } from 'react-spinners';

const Loading = () => {
  return (
    <div className="flex justify-center items-center h-[100vh]">
      <PulseLoader color="#b68d33" />
    </div>
  );
};

export default Loading;
