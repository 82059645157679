import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { logout } from "../../Auth/Login/login";
import { useNavigate } from "react-router-dom";

const actAddCart = createAsyncThunk("api/addcart", async (id, thunkAPI) => {
  const { rejectWithValue, getState, dispatch } = thunkAPI;
  const token = getState().login.token;
  const user_id = getState().profileSlice.cartprofile.id;

  try {
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("product_id", id);
    formData.append("amount", 1);

    const res = await axios.post(
      `/cart/add-to-cart`,
      formData,
      {
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          jwt: token,
        },
      }
    );

    return res.data;
  } catch (error) {
    if (error.response && error.response.status === 403) {
      // Dispatch logout and navigate to login page if 403 error occurs
      dispatch(logout());

      const navigate = useNavigate();
      navigate("/login"); // Redirect to the login page
    }

    return rejectWithValue(error.response?.data || error.message);
  }
});

export default actAddCart;
