import { Outlet } from "react-router-dom"
import Headerform from "../components/Common/Headers/Headerform"
import Footer from "../components/Common/Footers/Footer"

export const UserLayout = () => {
    return (
        <>
            <Headerform />
            <Outlet />
            <Footer />
        </>
    )
}


export default UserLayout