import { configureStore, combineReducers } from "@reduxjs/toolkit";
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  REGISTER,
  PURGE,
  PERSIST,
} from "redux-persist";
import storage from "redux-persist/lib/storage";


import adds from "./Adds/adds";
import categories from "./Category/categories";
import login from "./Auth/Login/login";
import register from "./Auth/Register/register";
import singeladd from "./AddDetails/singeladd";
import profileSlice from "./Auth/profileSlice/profileSlice";
import CartSlice from "./CartSlice/CartSlice";

// تكوين التخزين المحلي (persist configuration)
const persistedConfig = {
  key: "root",
  storage,
  whiteList: ["login", "register"], // القوائم التي سيتم تخزينها
};

const loginConfig = {
  key: "login",
  storage,
  whiteList: ["userInfo"], // حدد البيانات التي تريد تخزينها
};

// دمج جميع الـ reducers مع دعم التخزين (persistent reducer)
const rootReducers = combineReducers({
  adds,
  categories,
  login: persistReducer(loginConfig, login), // تطبيق التخزين على login
  register,
  singeladd,
  CartSlice,
  profileSlice
});

// دمج الـ rootReducers مع التخزين
const persistedReducer = persistReducer(persistedConfig, rootReducers);

// تكوين الـ store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, REGISTER, PURGE, PERSIST],
      },
    }),
});

// تصدير المتغيرات
const persistor = persistStore(store);

export { store, persistor };
