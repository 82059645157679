import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const actprofile = createAsyncThunk(
  "userProfile/actprofile",
  async (_, thunkAPI) => {
    const { getState } = thunkAPI;
    const token = getState().login.token; // Adjust the path based on where token is stored

    if (!token) {
      throw new Error("Token not found");
    }

    try {
      const res = await axios.get(`/auth/User`, {
        headers: {
          "Content-Type": "application/json",
           jwt : `${token}`, // Use Authorization header for token
        },
        withCredentials: true, // Ensure cookies are sent with the request
      });

      if (res.status === 404) {
        throw new Error("User not found");
      }
      console.log(res.data);
      return res.data;
    } catch (err) {
      console.error("Error fetching cart data:", err.message || err);
      throw err;
    }
  }
);
